import classNames from 'classnames/bind';
import styles from './TaskBar.module.scss';

import MienBac from './SearchArea/MienBac/MienBac';
import request from '../../Config/Connect';

import { useEffect, useState } from 'react';
import MienTrung from './SearchArea/MienTrung/MienTrung';
import MienNam from './SearchArea/MienNam/MienNam';

const cx = classNames.bind(styles);

function TaskBar() {
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);

    useEffect(() => {
        request.get('/api/jobmienbac').then((res) => {
            setData(res.data);
        });
    }, []);

    useEffect(() => {
        request.get('/api/jobmiennam').then((res) => {
            setData1(res.data);
        });
    }, []);

    useEffect(() => {
        request.get('/api/jobmientrung').then((res) => {
            setData2(res.data);
        });
    }, []);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div id="mienbac" className={cx('mienbac')}>
                    <h2>Miền Bắc</h2>
                    <MienBac data={data} />
                </div>
                <div id="miennam" className={cx('mienbac')}>
                    <h2>Miền Nam</h2>
                    <MienTrung data1={data1} />
                </div>
                <div id="mientrung" className={cx('mienbac')}>
                    <h2>Miền Trung</h2>
                    <MienNam data2={data2} />
                </div>
            </div>
        </div>
    );
}

export default TaskBar;
