import classNames from 'classnames/bind';
import styles from './ListContent.module.scss';
import CardBody from '../CardBody/CardBody';

const cx = classNames.bind(styles);

function ListContent({ news }) {
    return (
        <div className={cx('wrapper')}>
            {news.length > 0 ? (
                <div>
                    <CardBody news={news} />
                </div>
            ) : (
                <div className={cx('no-job')}>
                    <img src="https://static.topcv.vn/v4/image/job-list/none-result.png" alt="" />
                    <span>Chưa tìm thấy việc làm phù hợp với yêu cầu của bạn</span>
                </div>
            )}
            <div className={cx('')}>
                <h5>Ngành Liên Quan Khác</h5>
                {news.slice(0, 10).map((item) => (
                    <div key={item.id} className={cx('form-card')}>
                        <div className={cx('column-left')}>
                            <img src={item.Image} alt="" />
                        </div>
                        <div className={cx('column-right')}>
                            <h3>{item.TenCV}</h3>
                            <span style={{ fontWeight: '500' }}>Ngành : {item.Nganh}</span>
                            <br />
                            <span style={{ fontWeight: '500' }}>Web : {item.Web}</span>
                            <br />
                            <span style={{ fontWeight: '500' }}>Địa Điểm : {item.TinhThanh}</span>
                            <br />
                            <span style={{ fontWeight: '500' }}>Mức Lương : {item.Luong}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ListContent;
