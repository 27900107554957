import './App.css';
import Header from './Layouts/Header/Header';
import ListContent from './Layouts/ListContent/ListContent';
import Navbar from './Layouts/NavBar/Navbar';
import PageIframe1 from './Layouts/PageIframe/PageIframe1';
import PageIframe2 from './Layouts/PageIframe/PageIframe2';
import PageIframe3 from './Layouts/PageIframe/PageIframe3';
import SearchCV from './Layouts/SearchCV/SearchCV';
import request from './Config/Connect';

import { useEffect, useState } from 'react';
import TaskBar from './Layouts/TaskBar/TaksBar';

function App() {
    const [news, setNews] = useState([]);
    const [checkPage, setCheckPage] = useState('home-page');
    const [valueSearch, setValueSearch] = useState('');
    const [searchProvince, setSearchProvince] = useState('');
    const [type, setType] = useState(0);
    const [exp, setExp] = useState(0);

    useEffect(() => {
        request.get('api/data').then((res) => setNews(res.data));
    }, []);

    useEffect(() => {
        request
            .get('/api/search', {
                params: {
                    valueSearch: valueSearch,
                    searchProvince: searchProvince,
                    type: type,
                    exp: exp,
                },
            })
            .then((res) => setNews(res.data));
    }, [exp, type, searchProvince, valueSearch]);

    const scrollToComponent = (componentId) => {
        const element = document.getElementById(componentId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="App">
            <div>
                <Header />
                <Navbar setCheckPage={setCheckPage} scrollToComponent={scrollToComponent} />
                <SearchCV
                    setValueSearch={setValueSearch}
                    setSearchProvince={setSearchProvince}
                    setType={setType}
                    setExp={setExp}
                />
            </div>

            {checkPage === 'home-page' ? (
                <div>
                    <ListContent news={news} />
                    <TaskBar />
                </div>
            ) : (
                <></>
            )}
            {checkPage === 'page1' ? (
                <div>
                    <PageIframe1 />
                </div>
            ) : (
                <></>
            )}

            {checkPage === 'page2' ? (
                <div>
                    <PageIframe2 />
                </div>
            ) : (
                <></>
            )}

            {checkPage === 'page3' ? (
                <div>
                    <PageIframe3 />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

export default App;
