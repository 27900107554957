import classNames from 'classnames/bind';
import styles from './SearchCV.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
const cx = classNames.bind(styles);

function SearchCV({ setValueSearch, setSearchProvince, setType, setExp }) {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div className={cx('search')}>
                    <input placeholder="Vị trí tuyển dụng..." onChange={(e) => setValueSearch(e.target.value)} />
                    <FontAwesomeIcon id={cx('icons')} icon={faSearch} />
                </div>

                <div className={cx('search')}>
                    <input
                        placeholder="Nhập Tỉnh Thành Tìm Kiếm..."
                        onChange={(e) => setSearchProvince(e.target.value)}
                    />
                    <FontAwesomeIcon id={cx('icons')} icon={faSearch} />
                </div>

                <div className={cx('select')}>
                    <select
                        onChange={(e) => setType(e.target.value)}
                        class="form-select"
                        aria-label="Default select example"
                    >
                        <option value="0" selected>
                            Loại Hình
                        </option>
                        <option value="1">Bán thời gian</option>
                        <option value="2">Toàn thời gian </option>
                        <option value="3">Hình thức khác</option>
                    </select>
                </div>

                <div className={cx('select')}>
                    <select
                        onChange={(e) => setExp(e.target.value)}
                        class="form-select"
                        aria-label="Default select example"
                    >
                        <option value="0" selected>
                            Tất Cả Kinh Nghiệm
                        </option>
                        <option value="1">Chưa Có Kinh Nghiệm</option>
                        <option value="2"> Dưới 1 năm</option>
                        <option value="3">Từ 1 - 3 năm</option>
                        <option value="4">Từ 3 - 5 năm</option>
                        <option value="5">Trên 5 năm</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default SearchCV;
