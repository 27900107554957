import classNames from 'classnames/bind';
import styles from './MienBac.module.scss';

const cx = classNames.bind(styles);

function MienBac({ data }) {
    const handleOpenTab = (data) => {
        window.open(data);
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div>
                    {data.slice(0, 20).map((item) => (
                        <div key={item.id} onClick={() => handleOpenTab(item.Link)} className={cx('form-card')}>
                            <div className={cx('column-left')}>
                                <img src={item.Image} alt="" />
                            </div>
                            <div className={cx('column-right')}>
                                <h3>{item.TenCV}</h3>
                                <span style={{ fontWeight: '500' }}>Ngành : {item.Nganh}</span>
                                <br />
                                <span style={{ fontWeight: '500' }}>Web : {item.Web}</span>
                                <br />
                                <span style={{ fontWeight: '500' }}>Địa Điểm : {item.TinhThanh}</span>
                                <br />
                                <span style={{ fontWeight: '500' }}>Mức Lương : {item.Luong}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MienBac;
