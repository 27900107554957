import classNames from 'classnames/bind';
import styles from './PageIframe.module.scss';

const cx = classNames.bind(styles);

function PageIframe1() {
    return (
        <iframe
            className={cx('box-iframe')}
            title="123"
            src="https://app.powerbi.com/view?r=eyJrIjoiZGIxZTk2NmUtN2E0ZC00NjRlLTgxOTItOGUxMWI4MWY4ZTcwIiwidCI6IjJmODVkYzc0LWI2YjQtNDU4NC1iZWVlLWNjZGE3MTQ0NDk3MCIsImMiOjZ9"
        />
    );
}

export default PageIframe1;
