import classNames from 'classnames/bind';
import styles from './Header.module.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';

const cx = classNames.bind(styles);

function Header({ setValueSearch }) {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('logo')}>
                <img src="https://baomoi-static.bmcdn.me/web/styles/img/bm-logo.png" alt="" />
            </div>

            {/* <div className={cx('search')}>
                <input placeholder="Nhập Nội Dung Tìm Kiếm..." onChange={(e) => setValueSearch(e.target.value)} />
                <FontAwesomeIcon icon={faSearch} />
            </div> */}
        </div>
    );
}

export default Header;
