import classNames from 'classnames/bind';
import styles from './Navbar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

function Navbar({ setCheckPage, scrollToComponent }) {
    const [show, setShow] = useState(false);

    const [check, setCheck] = useState('');

    const handleShow = () => {
        setShow(!show);
    };

    const handleCheckPage = (data) => {
        setCheckPage(data);
    };

    const onclickArea = () => {
        setCheck(!check);
    };

    return (
        <>
            <div className={cx('wrapper')}>
                <div className={cx('inner')}>
                    <ul>
                        <li onClick={() => handleCheckPage('home-page')}>
                            <Link id={cx('link')} to="/">
                                Tin Tức Tuyển Dụng
                            </Link>
                        </li>

                        <li onClick={() => handleCheckPage('page1')}>
                            <Link id={cx('link')} to="">
                                Báo Cáo Tổng Quan Các Ngành
                            </Link>
                        </li>

                        <li onClick={() => handleCheckPage('page2')}>
                            <Link id={cx('link')} to="">
                                Báo Cáo Tổng Quan Các Ngành Con
                            </Link>
                        </li>

                        <li onClick={() => handleCheckPage('page3')}>
                            <Link id={cx('link')} to="">
                                Báo Cáo Tổng Quan Các Vị Trí Làm Việc
                            </Link>
                        </li>

                        <li onClick={onclickArea} style={{ position: 'relative' }}>
                            Khu Vực
                            <ul style={check ? { display: 'flex' } : { display: 'none' }} className={cx('dropdown')}>
                                <li onClick={() => scrollToComponent('mienbac')}>Miền Bắc</li>
                                <li onClick={() => scrollToComponent('miennam')}>Miền Nam</li>
                                <li onClick={() => scrollToComponent('mientrung')}>Miền Trung</li>
                            </ul>
                        </li>
                    </ul>
                    <div onClick={handleShow} className={cx('btn-bars', show ? '' : 'show')}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;
